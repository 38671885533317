<template>
	<div class="app-view">
		<div class="controls-container settings-container">
      <div class="controls-container-headline settings-container-headline">Nutzungsrichtung:</div>
      <div class="control-buttons-wrapper">
        <div class="control-button">
          <button :class="{silobutton: true, active: this.selectedArt == 'Silo' }" data-target="searchterm-filter" @click="this.selectedArt = 'Silo'"><span class="button-title">Silomais</span></button>
        </div>
        <div class="control-button">
          <button :class="{kornbutton: true, active: this.selectedArt == 'Koerner' }" data-target="sort-filter" @click="this.selectedArt = 'Koerner'"><span class="button-title">Körnermais</span></button>
        </div>
      </div>

      <div class="settings-container">
        <div class="settings-container-headline">Standort:</div>
        <div class="select-wrapper">
          <select v-model="selectedLocation">
            <option v-for="loc in locations" :key="loc">{{loc}}</option>
          </select>
        </div>
      </div>
      
      <div class="settings-container">
        <div class="settings-container-headline">Chart:</div>
        <div class="select-wrapper">
          <select v-model="selectedConf">
            <option v-for="conf in filteredAxisConfig" :key="conf.TID" :value="conf.TID">{{conf.Chartname}}</option>
          </select>
        </div>
      </div>
      
      <div class="settings-container vergleichssorten">
        <div class="settings-container-headline">Auswahl Vergleichssorten:</div>
        <div class="filter-outer-wrapper">
          <div class="filter-wrapper">
            <div class="filter-group-wrapper" data-filter-group="Vergleichssorten">
              <div class="filter-list">
                <div class="single-filter filter-checkbox" v-for="sorte in vergleichssorten" :key="sorte.ID">
                  <input :id="`sorte-${sorte.ID}`" type="checkbox" :value="sorte.Sorte" v-model="selectedSorten" />
                  <label :for="`sorte-${sorte.ID}`" class="filter-title">{{ sorte.Sorte }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrapper text-left">
        <button class="btn btn-primary" @click="reloadSorten()">Vergleichssorten zurücksetzen</button>
      </div>
      <div class="sorten-overview">{{axisConfigTitle}}<br/> in {{selectedLocation}}</div>
      <div class="button-wrapper">
        <button type="button" class="btn btn-primary btn-block" @click="drawChart()">Chart anzeigen</button>
      </div>
      <div class="sorten-footnote">
        <p>
          {{selectedSorten.length }} von {{ vergleichssorten.length }} Sorten werden angezeigt.<br/>
          Quelle: Exaktversuch (2020), Staphyt GmbH, Detailansicht
        </p>
      </div>
      <div class="amchart-outer-wrapper" v-show="showChart">
        <div class="amchart-close">Schließen <button @click="killChart()">&times;</button></div>
        <div class="amchart-wrapper"></div>
        <div class="sorten-footnote">
          <p>
            {{selectedSorten.length }} von {{ vergleichssorten.length }} Sorten werden angezeigt.<br/>
            Quelle: Exaktversuch (2020), Staphyt GmbH, Detailansicht
          </p>
        </div>
      </div>

		</div>

	</div>
</template>



<style lang="scss">

body { margin: 0; }

.text-left { text-align: left; }

// .maisscout {
//   background-color: #EEEEEE;
//   .app-inner {
//     display: flex; flex-direction: column;
//     height: 100vh;
//   }
// }

.settings-container {
  .settings-container-headline {
    color: #B5B5B5;
    font-size: 16px; font-weight: 700; line-height: 22px;
    text-align: left; text-transform: uppercase;
  }
}

.vergleichssorten {
  .filter-list {
    max-height: 224px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar { width: 0; height: 0; }
  }
}

.controls-container {
  background-color: #EEEFEE;
  padding: 20px; padding-bottom: 0;
  .control-buttons-wrapper {
    display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;
    margin-left: -2px; margin-right: -2px; margin-bottom: 10px;
    .control-button {
      flex: 0 0 calc(100%/2 - 4px);
      padding-left: 2px; padding-right: 2px;
      button {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        background-image: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
        border: 1px solid #C3C3C3; border-radius: 3px;
        color: #1D1D1F;
        display: block;
        font-size: 16px; line-height: 1.5;
        padding: 8px 40px;
        position: relative;
        width: 100%;
        &:focus, &.active {
          border-color: #E73136;
          color: #E73136;
          outline: none; 
          &::before {
            opacity: 0;
          }
          &::after {
            opacity: 1;
          }
        }
        &::before {
          background-position: top center; background-repeat: no-repeat; background-size: contain;
          content: '';
          display: block;
          position: absolute; top: 6px; left: 50%;
          transform: translateX(-50%);
          width: 18px; height: 18px;
        }
        &::after {
          background-position: top center; background-repeat: no-repeat; background-size: contain;
          content: '';
          display: block;
          position: absolute; top: 6px; left: 50%;
          transform: translateX(-50%);
          width: 18px; height: 18px;
          opacity: 0;
        }
        &.silobutton, &.kornbutton {
          background-position: left 4px center, top left;
          background-repeat: no-repeat, no-repeat;
        }
        &.silobutton {
          background-image: url("/img/icons/check_silomais.svg"), linear-gradient(to bottom, #FFFFFF, #EAEAEA);
        }
        &.kornbutton {
          background-image: url("/img/icons/check_koernermais.svg"), linear-gradient(to bottom, #FFFFFF, #EAEAEA);
        }
        .button-title { pointer-events: none; }
      }
    }
  }
}

.filter-searchterm-wrapper {
  padding-left: 20px; padding-right: 20px;
  input[type=text] {
    margin-top: 20px; margin-bottom: 20px;
    font-size: 18px; line-height: 23px;
    padding: 9px; padding-left: 36px;
    border: 1px solid #C3C3C3;
    border-radius: 3px;
    box-sizing: border-box;
    background: #FFFFFF;
    background-image: url("/img/icons/icon_search.svg"); background-repeat: no-repeat; background-position: top 12px left 9px;
    width: 100%;
    &:focus { outline: none; }
  }
}
.select-wrapper {
  background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
  height: 40px;
  margin-bottom: 9px;
  position: relative;
  &::after {
    border-top: 3px solid #C3C3C3; border-right: 3px solid #C3C3C3; border-bottom: 0; border-left: 0;
    content: '';
    display: inline-block;
    padding-top: 6px; padding-right: 6px;
    position: absolute; top: 15px; right: 15px;
    transform: rotate(135deg);
  }
  select {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #C3C3C3; border-radius: 3px;
    background: transparent;
    font-size: 16px;
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
    padding: 9px 12px;
    &:focus { outline: none; }
  }
}
.filter-outer-wrapper {

  // margin-top: 16px;
  margin: 0 -20px 12px;

  .filter-wrapper-title { margin-bottom: 16px; }
  .filter-wrapper {
    background-color: #ffffff;
    display: flex; align-items: center; flex-wrap: wrap;
    // max-height: 240px;
    // overflow-y: scroll;
    .filter-group-wrapper {
      flex: 0 0 100%;
      display: flex; align-items: center; flex-wrap: wrap;
      .filter-group-title {
        color: #B5B5B5;
        flex: 0 0 100%;
        font-size: 16px; font-weight: 700; line-height: 22px;
        padding: 9px 20px;
        text-transform: uppercase;
        border-bottom: 1px solid #dedede;
        border-top: 1px solid #dedede;
      }
      &:first-child {
        .filter-group-title {
          border-top: none;
        }
      }
    }
    
    .single-filter {
      flex: 0 0 100%;
      // margin-bottom: 16px;
      &.filter-range {
        margin-bottom: 16px;
        .filter-title {
          margin-left: 21px;
        }
      }
      &.filter-checkbox{
        cursor: pointer;
        position: relative;
        .filter-title { 
          cursor: pointer;
          display: block;
          padding: 5px 20px;
          padding-left: 60px;
          position: relative;
          text-align: left;
          user-select: none;
          // &::before {
          //   // background-color: red;
          //   content: '';
          //   pointer-events: none;
          //   position: absolute; top: 0; left: 0;
          //   width: 30px; height: 30px;
          // }
        }
        input[type=checkbox] {
          --webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          margin: 0;
          position: absolute; top: 5px; left: 20px;
          width: 30px; height: 30px;
          z-index: 1;
          background-image: url("/img/icons/check_inactive.svg");
          background-position: center; background-repeat: no-repeat; background-size: 23px;
          &:checked {
            background-image: url("/img/icons/check_active.svg");
            + label { background-color: #D9EBE7; }
          }
          &:focus {
            outline: none;
          }
        }
      }
      &.image-checkbox {
        input[type=checkbox]{
          &[data-img="Silomais"], &[data-img="Koernermais"], &[data-img="Biogasmais"] {
            filter: grayscale(1);
            &:checked {
              filter:grayscale(0);
            }
            &:focus {
              outline: none;
            }
          }
          &[data-img="Silomais"] {
            background-image: url("/img/icons/check_silomais.svg");
          }
          &[data-img="Koernermais"] {
            background-image: url("/img/icons/check_koernermais.svg");
          }
          &[data-img="Biogasmais"] {
            background-image: url("/img/icons/check_biogasmais.svg");
          }
        }
      }
      .filter-title {
        // margin-bottom: 8px;
        font-size: 16px; line-height: 30px;
        label {
          user-select: none;
        }
      }
      .filter-config {
        padding-left: 20px; padding-right: 20px;
      }
    }
  }
  .range-slider {
    width: 100%;
    margin: auto;
    text-align: center;
    position: relative;
    height: 1.5rem;
    input[type=range] {
      position: absolute;
      left: 0;
      top: .5rem;
    }

    input[type=number] {
      border: 1px solid #ddd;
      text-align: center;
      font-size: 1.6em;
      -moz-appearance: textfield;
    }

    input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button { -webkit-appearance: none; }
    input[type=number]:invalid, input[type=number]:out-of-range { border: 2px solid #ff6347; }
    input[type=range] { -webkit-appearance: none; width: 100%; }
    input[type=range]:focus { outline: none; }

    input[type=range]:nth-child(2){
          background-color: transparent;
          &::-webkit-slider-runnable-track { background-color: transparent; }
          &::-moz-range-track { background-color: transparent; }
          &:focus {
            background-color: transparent;
            &::-webkit-slider-runnable-track { background-color: transparent; }
            &::-moz-range-track { background-color: transparent; }
          }
        }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 12px;
      cursor: pointer;
      animate: 0.2s;
      background-image: linear-gradient(to right, #E0DFDF 0, #E0DFDF 2%, #C11E22 2%, #C11E22 98%, #E0DFDF 98%, #E0DFDF 100%);
      background-size: 10%;
      border-radius: 1px;
      box-shadow: none;
      border: 0;
    }

    input[type=range]::-webkit-slider-thumb {
      z-index: 2;
      position: relative;
      box-shadow: 0px 0px 0px #000;
      border: 1px solid #C3C3C3;
      height: 30px;
      width: 22px;
      border-radius: 3px;
      background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
    }
  }

}
.comparison-outer-wrapper {
  background-color: #007858;
  color: #FFFFFF;
  // margin-bottom: 16px;

  .comparison-wrapper-title { margin-bottom: 16px; }

  .comparison-wrapper {
    padding: 13px 20px;
    display: flex; align-items:center; justify-content: space-between;
    .comparison-description {
      font-size: 11px; line-height: 16px;
    }
    .comparison-button-wrapper {
      .comparison-button {
        background-color: transparent;
        color: #FFFFFF;
        cursor: pointer;
        border: 1px solid #FFFFFF;
        border-radius: 3px;
        font-size: 14px; line-height: 20px;
        margin-left: 12px;
        padding: 6px 24px;
        text-transform: uppercase;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        &:focus {
          outline: none;
        }
        &:hover {
          color: #007858;
          background-color: #FFFFFF;
        }
      }
    }
  }
}

.filter-container-wrapper {
  background-color: #ffffff;
  margin-left: -20px; margin-right: -20px;
  max-height: 240px;
  overflow-y: scroll;
  text-align: left;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .filter-container {
    // display: none;
    height: 0;
    overflow: hidden;
    position: relative;
    transition: height 0.3s ease-in-out;
    &.comparison-outer-wrapper {
      position: absolute; bottom: 0; left: 0; right: 0;
      z-index: 2;
    }
    &#searchterm-filter {
      background-color: #EEEFEE;
    }
    &[data-active="true"]{
      overflow: visible;
      &#searchterm-filter {
        padding-bottom: 20px;
      }
    }
  }
}

.amchart-outer-wrapper {
  position: fixed; top: 0; left: 0; bottom: 0; right: 0;
  z-index: 1;
  .amchart-close {
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    display: flex; align-items: center;
    font-size: 0;
    position: absolute; top: 8px; right: 16px;
    user-select: none;
    z-index: 1000;
    button {
      background-color: #E73136;
      border: 2px solid #FFFFFF; border-radius: 50%; 
      color: #FFFFFF;
      cursor: pointer;
      display: inline-flex; align-items: center; justify-content: center;
      font-size: 30px; font-weight: 400; line-height: 1;
      margin-left: 4px;
      &:focus { outline: none; }
    }
  }
  .amchart-wrapper {
    background-color: #ffffff;
    position: fixed; top: 32px; left: 0; right: 0; bottom: 64px;
    z-index: 999;
  }
  .sorten-footnote {
    background-color: #ffffff;
    position: fixed; bottom: 0; left: 0; right: 0;
    padding-left: 20px; padding-right: 20px;
  }
}

.sorten-overview {
  color: #E73136;
  font-size: 16px; font-weight: 700; line-height: 1.5;
  margin-top: 8px; margin-bottom: 8px;
  text-transform: uppercase;
}

.sorten-footnote {
  font-size: 14px; line-height: 18px;
  text-align: left;
}

.btn{
  border: none;
  cursor: pointer;
  font-size: 18px; line-height: 24px;
  padding: 8px 16px;
  text-align: center;
  &.btn-primary {
    background-color: #007858;
    color: #ffffff;
    text-transform: uppercase;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &:focus {
    outline: none;
  }
}

@media screen and (min-width: 1025px) {
  .amchart-outer-wrapper {
    padding-top: 238px;
    background-color: rgba(0, 0, 0, .2);
    .amchart-wrapper {
      top: 238px;
    }
    .amchart-close {
      background-color: #007858;
      color: #ffffff;
      font-size: 16px;
      padding-left: 4px;
      top: 246px;
      button {
        margin-top: -4px; margin-bottom: -4px; margin-right: -8px;
      }
    }
  }
}

</style>

<script>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themesAnimated from "@amcharts/amcharts4/themes/animated"
import axios from 'axios'
// import XLSX from 'xlsx'

export default {
  name: 'Maisscout',
		data(){
			return {
        isLoaded: false,
        showChart: false,
        selectedLocation: 'Buxtehude 2020',
        selectedArt: 'Silo',
        selectedConf: 1,
        selectedSorten: [],

        //Dimo data
        chart: {},
        axisConfig: {},
        experimentsData: {},
			}
		},
    computed: {
      axisConfigTitle: {
        get(){
          if(this.isLoaded) {
            const selectedConf = this.axisConfig.find(conf => {if(conf.TID === this.selectedConf) return conf})
            return (typeof selectedConf !== "undefined") ? selectedConf.Chartname : ""
          }
          else return ""
        }
      },
      locations: {
        get(){
          if(this.isLoaded){
            let ret = this.experimentsData.map(experiment => experiment.Standort)
            ret = new Set(ret)
            return ret
          }
          else return []
        }
      },
      //Dimo
      filteredExperimentsData: {
        get() { 
          // const filtered = this.experimentsData

          // filtered.filter(singleData => {
          //   if(this.selectedArt == singleData.) return
          // })
          let filtered = this.experimentsData.filter(singleData => {
            if(this.selectedSorten.includes(singleData.Sorte)) return singleData
          })

          filtered = filtered.filter(singleData => {
            if(this.selectedLocation == singleData.Standort) return singleData
          })

          return filtered
        }
      },
      filteredAxisConfig: {
        get(){
          if(this.isLoaded) {
            const art = (this.selectedArt === "Silo") ? "Silo" : "Korn"
            return this.axisConfig.filter(conf => {
              if(conf.Art == art) return conf
            })
          }
          else return {}
        }
      },
      vergleichssorten: {
        get(){
          if(this.isLoaded){
            
            const newConf = this.filteredAxisConfig.find(conf => { if(conf.TID == this.selectedConf) return conf})
            const res = this.experimentsData.filter(singleData => { if(this.selectedLocation == singleData.Standort && singleData.Sorte !== "Mittel" && singleData.Sorte.indexOf("V-Sorte") == -1) return singleData })
            // return res.sort((a, b) => {return (a.Sorte.toLowerCase() > b.Sorte.toLowerCase()) ? 1 : -1})
            if(typeof newConf !== 'undefined') res.sort((a, b) => { return b[newConf.order_by] - a[newConf.order_by] })
            return res.sort((a, b) => { return a["V-Sorte"] - b["V-Sorte"] })
          }
          else{
            return {}
          }
        }
      }
    },
    watch: {
      vergleichssorten(){
        if(this.isLoaded){
          const resArr = []
          const vSorten = this.vergleichssorten.filter(singleSorte => {if(singleSorte["Eigensorte"] == 0) return singleSorte})
          vSorten.forEach(vSorte => resArr.push(vSorte.Sorte))
          this.selectedSorten = resArr
        }
        else this.selectedSorten = []

      },
      selectedConf(newValue){
        const newConf = this.filteredAxisConfig.find(conf => { if(conf.TID == newValue) return conf})
        if(typeof newConf !== 'undefined') this.vergleichssorten.sort((a, b) => { return b[newConf.order_by] - a[newConf.order_by] })
        this.vergleichssorten.sort((a, b) => { return a["V-Sorte"] - b["V-Sorte"] })
      },
      selectedArt(newValue){
        this.isLoaded = false
        const url = 'https://stroetmann-saat.de/' + newValue + '_experiments.json'
        // const url = 'https://stroetmann-saat.de/utility/?tx_stroetmannext_utility[action]=getCornExperimentsData&tx_stroetmannext_utility[controller]=LsvMais&tx_stroetmannext_utility[type]=' + newValue.toLowerCase()
        axios.get(url).then(response => {
          this.experimentsData = response.data
          this.isLoaded = true
          setTimeout(() => {
            const resArr = []
            const vSorten = this.vergleichssorten.filter(singleSorte => {if(singleSorte["Eigensorte"] == 0) return singleSorte})
            vSorten.forEach(vSorte => resArr.push(vSorte.Sorte))
            this.selectedSorten = resArr
            this.selectedLocation = this.locations.values().next().value
            this.selectedConf = this.filteredAxisConfig[0].TID
          }, 1)
          

        })
      }
    },
		methods: {
      //Dimo

      setData() {

        const data = []
        const ac = this.filteredAxisConfig.find(conf => { if(conf.TID == this.selectedConf) return conf})
        Object.values(this.filteredExperimentsData).forEach ( val => {


          data.push({ 
            standort: val['Standort'],
            sorte: val['Sorte'],
            eigen: val['Eigensorte'],
            val1: parseFloat(val[ac["X-Achse"]]) , 
            val2: parseFloat(val[ac["Y-Achse"]])//,
            // val3: (parseFloat(val['TS-Korn_rel']) / parseFloat(val['TM_86']))/4
          })

        })
        const mitteldata = this.experimentsData.find(singleData => {
          if(singleData.Sorte === "Mittel" && this.selectedLocation == singleData.Standort) return singleData
        })
        data.push({ 
            standort: mitteldata['Standort'],
            sorte: mitteldata['Sorte'],
            eigen: -1,
            val1: parseFloat(mitteldata[ac["X-Achse"]]) , 
            val2: parseFloat(mitteldata[ac["Y-Achse"]])//,
          })

        return data ?? '(_x_)'
      },

      drawChart() {
        this.killChart()
        const ac = this.filteredAxisConfig.find(conf => { if(conf.TID == this.selectedConf) return conf})
        am4core.useTheme(am4themesAnimated)
        const chart = am4core.create("amchart-wrapper", am4charts.XYChart)

        chart.hiddenState.properties.opacity = 0 // this creates initial fade-in
        chart.maxZoomLevel = 1
        chart.maskBullets = false
        // chart.startLocation = 0.4
        // chart.endLocation = 0.6
        const xAxis = chart.xAxes.push(new am4charts.ValueAxis())
        const yAxis = chart.yAxes.push(new am4charts.ValueAxis())
        xAxis.title.text = ac["X-Klarname"]
        yAxis.title.text = ac["Y-Klarname"]

        // chart.paddingTop = 20
        // chart.paddingTop = 20

        // switch(this.selectedArt){
        //   case 'Silo': 

        //   break

        //   case 'Korn':

        //   break
        // }


        const series = chart.series.push(new am4charts.LineSeries())
        series.dataFields.valueX = "val1"
        series.dataFields.valueY = "val2"
        series.dataFields.value = "eigen"
        series.strokeOpacity = 0
        series.sequencedInterpolation = false

        const bullet = series.bullets.push(new am4charts.LabelBullet())
        const image = bullet.createChild(am4core.Image)
        image.width = 30
        image.height = 30
        image.horizontalCenter = "middle"
        image.verticalCenter = "middle"
        bullet.fillOpacity = 1

        bullet.tooltipText = `[bold]{sorte}[/]\n${ac['X-Klarname']}: {valueX}\n${ac['Y-Klarname']}: {valueY}`

        bullet.label.text = "[bold]{sorte}[/]"
        bullet.label.textAlign = "middle"
        bullet.label.dy = 20
        bullet.label.fontSize = 10

        const hoverState = bullet.states.create("hover")
        hoverState.properties.fillOpacity = 1
        hoverState.properties.strokeOpacity = 1

        image.adapter.add("href", function (href, target) {
          
          const value = target.dataItem.values.value.value
          let ret = "/img/circle.svg"
          switch(value){
            case -1:
              ret = "/img/mittel.svg"
            break

            case 0:
              ret = "/img/circle.svg"
            break

            case 1:
              ret = "/img/stroetmann-krone.svg"
            break


          }
          return ret
        })

        // chart.cursor = new am4charts.XYCursor()
        // chart.cursor.behavior = "zoomXY"
        // chart.cursor.lineX.disabled = true
        // chart.cursor.lineY.disabled = true

        chart.data = this.setData()

        const lowestX = chart.data.reduce(function(prev, curr) {
            return prev.val1 < curr.val1 ? prev : curr;
        });
        const highestX = chart.data.reduce(function(prev, curr) {
            return prev.val1 > curr.val1 ? prev : curr;
        });
        const lowestY = chart.data.reduce(function(prev, curr) {
            return prev.val2 < curr.val2 ? prev : curr;
        });
        const highestY = chart.data.reduce(function(prev, curr) {
            return prev.val2 > curr.val2 ? prev : curr;
        });

        xAxis.min = lowestX.val1 - .5
        xAxis.max = highestX.val1 + .5
        yAxis.min = lowestY.val2 - 5
        yAxis.max = highestY.val2 + 5


        this.chart = chart
        this.showChart = true
      },
      killChart(){
        this.showChart = false
        if (this.chart !== null && typeof this.chart._className !== 'undefined' && this.chart._className == 'XYChart') {
          am4core.disposeAllCharts()
          this.chart = null
        }
      },
      reloadSorten(){
          const resArr = []
          const vSorten = this.vergleichssorten.filter(singleSorte => {if(singleSorte["Eigensorte"] == 0) return singleSorte})
          vSorten.forEach(vSorte => resArr.push(vSorte.Sorte))
          this.selectedSorten = resArr
      }
		},
		mounted() {
      //Dimo
  
      // const configExperimentsCall = axios.get('https://portal.stroetmann.de/kupoapi_v2/mais/experiments_axis.json')
      const configExperimentsCall = axios.get('https://stroetmann-saat.de/experiments_axis.json')
      // const siloExperimentsCall = axios.get('https://portal.stroetmann.de/kupoapi_v2/mais/Silo_experiments.json')
      const siloExperimentsCall = axios.get('https://stroetmann-saat.de/Silo_experiments.json')
      // const siloExperimentsCall = axios.get('https://stroetmann-saat.de/utility/?tx_stroetmannext_utility[action]=getCornExperimentsData&tx_stroetmannext_utility[controller]=LsvMais&tx_stroetmannext_utility[type]=silo')

      axios.all([configExperimentsCall, siloExperimentsCall]).then(axios.spread((...response) => {
        this.axisConfig = response[0].data
        // console.log(this.axisConfig)
        this.experimentsData = response[1].data
        this.isLoaded = true
        setTimeout(() => {
          this.selectedLocation = this.locations.values().next().value
          this.selectedConf = this.filteredAxisConfig[0].TID
          const resArr = []
          const vSorten = this.vergleichssorten.filter(singleSorte => {if(singleSorte["Eigensorte"] == 0) return singleSorte})
          vSorten.forEach(vSorte => resArr.push(vSorte.Sorte))
          this.selectedSorten = resArr
        }, 1)

        // this.initChart()
      }))

		},
    beforeUnmount() {
      //Dimo
      this.killChart()
    }
}
</script>